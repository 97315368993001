/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/componentsV2/sections/Header"
import { Footer } from "src/sections/landing"
import WidthWrapper from "src/components/widthWrapper"
import styled from "styled-components"
import constant from "src/utils/constant"

const Br = styled.br`
  content: "";
  margin: 1em;
  display: block;
  font-size: 50%;
`

const TermsDoc = () => (
  <div className="leading-loose pb-24 pt-90/16 md:pt-40">
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | Terms & Conditions</title>
      <meta name="description" content="" />
    </Helmet>
    <h1>Terms of Use</h1>
    <p>
      Please read the following carefully as it governs your use of the Web
      pages hosted by AirMason.com and/or its various affiliates and partners
      that are owned or managed by AirMason.com (collectively referred to as
      "AirMason", “we” or the "Site"). By accessing or using the Site, you
      expressly agree and consent to the following terms and conditions. If you
      do not accept the terms and conditions stated here, do not use the
      AirMason Sites.
    </p>

    <h2>1. SCOPE OF AGREEMENT</h2>
    <p>
      These Terms of Use apply to any User who accesses the Site. A "User" is an
      end user who registers on our Site to create a Document, download or print
      any information, whether published by AirMason or created by the User, or
      makes use of any of the Site’s services and associated tools. A User also
      includes anyone who uses any of our products or tools without registering
      on our Site, e.g., to participate in a free trial, or consume any content
      on the Site. These Terms of Use apply to all content and services made
      available through the Site, including those not available as of the
      "Effective Date” above.
    </p>
    <h2>2. YOUR REGISTRATION OBLIGATIONS</h2>
    <p>
      Fulfill your order Send you an order confirmation Assess the needs of your
      business to determine suitable products Respond to customer service
      requests Send you a newsletter Send you marketing communications Respond
      to your questions and concerns Improve our Web site and marketing efforts
      Conduct research and analysis Electronic and Web Browsing Information We
      Gather As is true of most Web sites, we automatically gather information
      about your computer such as your IP address, Browser type, Browser
      settings, navigation through our site(s), referring/exit pages, and
      operating system. This information does not include Personal Information.
      We may use third party tools and analytical services to gather and analyze
      this information.
    </p>
    <h2>3. USE OF SITE CONTENT AND MATERIALS</h2>
    <p>
      AirMason grants you a non-exclusive, non-transferable, revocable license
      (a) to access (but not through scraping, spidering, crawling or other
      technology or software used to access data without the express written
      consent of AirMason ) and use the Site strictly in accordance with this
      Agreement; (b) to use the Site solely for personal, non-commercial
      purposes; (c) to share your results with friends, relatives and trusted
      advisors for the purpose of assisting you with your personal matters or
      educating them about the availability of AirMason ; (d) to print out
      discrete information from the Site solely for personal, non-commercial
      purposes so long as you maintain all copyright and other policies
      contained therein. No print out or electronic version of any part of the
      Site or its contents may be used by you in any litigation or arbitration
      matter whatsoever under any circumstances. Your license for access and use
      of the Site and any information, materials or Documents therein
      (collectively defined as "Content and Materials") are subject to the
      following restrictions and prohibitions on use: You may not (a) copy,
      print (except for the express limited purpose permitted), republish,
      display, distribute, transmit, sell, rent, lease, loan or otherwise make
      available in any form or by any means all or any portion of the Site or
      any Content and Materials retrieved therefrom without AirMason 's express
      written permission; (b) use the Site or any materials obtained from the
      Site to develop, or as a component of, any information, storage and
      retrieval system, database, information base, or similar resource (in any
      media now existing or hereafter developed), that is offered for commercial
      distribution of any kind, including through sale, license, lease, rental,
      subscription, or any other commercial distribution mechanism.
    </p>
    <h2>4. SITE RULES</h2>
    <p>
      1. In order to provide a welcoming and productive community on our Site
      and to improve the safety and security of our Site, we require that you
      adhere to the following rules:
      <Br /> (a) Do not give your user name or password to others. You are
      responsible for maintaining the confidentiality of your user name and
      password in order to protect your information. You are responsible for all
      uses of your registration, whether or not authorized by you. If others use
      your password to post inappropriate material on the Site, you risk losing
      your access to the Site. You agree to notify AirMason immediately of any
      unauthorized use of your registration and password. <Br />
      (b) Do not respond to postings by other AirMason users in any manner or
      for any purpose other than that which is expected.
      <Br /> (c) Do not present AirMason Content or Materials (or permit Content
      and Materials to be presented) so that it appears to be available from a
      third party Web site.
      <Br /> (d) Do not delete or revise any material posted by any other person
      or entity. <Br />
      (e) Do not violate or attempt to violate the security of the Site.
      <Br /> (f) Do not access data not intended for your use. <Br />
      (g) Do not log into a server or account which you are not authorized to
      use. <Br />
      (h) Do not use the Site to advertise or offer to sell or buy any goods or
      services.
      <Br />
      (i) Do not use the Site for any unlawful purpose or in any manner that
      would violate international, federal or local laws or regulations, that
      would infringe any third party's intellectual property rights or in a
      manner that is defamatory, obscene, abusive or hateful. <Br />
      (j) Do not attempt to probe, scan or test the vulnerability of a system or
      network or to Breach security or authentication measures without proper
      authorization.
      <Br />
      (k) Do not attempt to interfere with service to any User, host or network,
      including, without limitation, via means of submitting a virus to the
      Site, overloading, "flooding", "mail bombing" or "crashing". <Br />
      (l) Do not modify, adapt, translate, reverse engineer, decompile or
      disassemble any portion of the Site. <Br />
      (m) Do not send SPAM or commercial emails of any kind and do not send any
      unsolicited e-mail to individuals, entities or AirMason Users. <Br />
      (n) Do not forge any TCP/IP packet header or any part of the header
      information in any e-mail or newsgroup posting. <Br />
      (o) Do not attempt to or actually access the Site by any means other than
      through the interfaces provided by the Site or by navigating to
      http://www.AirMason.com using a web Browser. This prohibition includes
      accessing or attempting to access the Site using any third-party service,
      including software-as-a-service platforms that aggregate access to
      multiple services, including AirMason. <Br />
      (p) Do not use manual or automated software, devices, script robots, other
      means or processes to access, "scrape," "crawl," or "spider" any web pages
      or other services contained in or on the Site. <Br />
      (q) Do not utilize or copy information, content or any data you view on
      and/or obtain from the Site to provide any service that is competitive, in
      AirMason's sole discretion, with the Site. <Br />
      (r) Report inappropriate postings or conduct to AirMason.
      <Br /> 2. Your violation of any of these Rules may result in civil or
      criminal liability. AirMason will investigate occurrences relating to such
      violations and may involve and cooperate with law enforcement authorities
      in prosecuting Users involved in such violations. If AirMason determines
      you misled AirMason or violated these rules or the Terms of Use, we
      reserve the right to terminate this Agreement and your use of the Site.
      AirMason is under no obligation to monitor the conduct of its Users, but
      it may investigate and respond when violations are reported. It is your
      responsibility to ensure that your use of the Site complies with these
      Terms of Use and all applicable laws.
    </p>
    <h2>5. UPLOADING/POSTING OR DISTRIBUTING FORMS AND DOCUMENTS</h2>

    <p>
      1. In connection with certain services available on the Site, such as the
      Form or Document product(s), you may elect to upload and distribute
      documents or other materials to the Site ("Documents"). Your submission of
      information to the Site, including the uploading of Documents is also
      governed by AirMason's Privacy Policy, the terms of which are fully
      incorporated by reference herein. You agree that you are solely
      responsible for the content of any Document you post to the Site and any
      consequences arising from such posting. In order to maintain the integrity
      of our Site and promote a positive experience for all users, you must
      adhere to the following rules:
      <Br /> (a) Your Documents may not contain: (i) confidential or private
      information belonging to others including material non-public information
      about an individual, company or entity which you do not have the right to
      transmit or publish or hold in your possession, for whatever reason; (ii)
      material that infringes on or misappropriates any other intellectual
      property rights, or violates the privacy or publicity rights of others;
      (iii) white text keywords (including any words embedded in the Document
      and hidden from the User); (iv) anything that is sexually explicit,
      obscene, libelous, defamatory, threatening, harassing, abusive, or
      hateful; (v) anything that is embarrassing or offensive to another person
      or entity; (vi) anything that would give rise to criminal or civil
      liability, (vii) anything that encourages conduct that constitutes a
      criminal offense; (viii) anything that encourages or provides
      instructional information about illegal activities or activities such as
      "hacking," "cracking," or "phreaking"; (ix) any virus, worm, Trojan horse,
      Easter egg, time bomb, spyware, malware or other computer code, file, or
      program that is harmful or invasive or may, or is intended, to damage or
      hijack the operation of, or to monitor the use of, any hardware, software
      or equipment; or (x) any unsolicited or unauthorized advertising,
      promotional material, "junk mail," "spam," "chain letter," "pyramid
      scheme" or investment opportunity, or any other form of solicitation.
      <Br /> (b) You may not use your Documents to: (i) impersonate another
      person, living or dead; (ii) post false, inaccurate or misleading
      information; (iii) post advertisements or solicitations of business; (iv)
      post chain letters or pyramid schemes; (v) post opinions or notices,
      commercial or otherwise; or (vi) restrict or inhibit any other person from
      using the Site (including without limitation by hacking or defacing any
      portion of the Site). <Br />
      (c) Document Documents must (i) contain the accurate Document of a living
      individual who also is the User; (ii) not contain anything indicating that
      others should email for more details. Documents may not charge a fee or
      restrict complete access to all Document information. <Br />
      (d) Where applicable, all Documents must be updated regularly to ensure
      that they are true, accurate, and complete. <Br />
      (e) If you have an idea or information that you want to remain
      confidential or do not want others to use, or that is subject to third
      party rights that may be infringed by your sharing it, do not post it to
      any portion of the AirMason Site or to any other site through the AirMason
      Site. AirMason IS NOT RESPONSIBLE FOR A USER'S MISUSE OR MISAPPROPRIATION
      OF ANY CONTENT OR INFORMATION YOU POST ON OR THROUGH OUR SITE. <Br />
      2. AirMason has no obligation to review Documents posted on the Site, but
      it may elect to do so. Documents found to violate these Terms of Use may
      be removed at AirMason's discretion and may result in AirMason terminating
      your use of the Site or our services. <Br />
      3. Special Terms Related To Profiles and Documents: Please note that, as
      part of the services offered on our Site, AirMason allows the creation of
      an electronic profile and email address for individuals who register for
      the Site (our Users) and for whom a Document is stored to the Site
      (“Profiles”). These profiles may include enhanced features such as
      graphics, images, text, data posted messages etc. AirMason will not screen
      or review any of the User posted/uploaded materials to determine
      suitability for employment nor will it consider any of the individuals for
      employment with AirMason. AirMason is not an agent for any individual
      submitting his or her Document or other materials through the Site or any
      company that may view an individual's Document or materials through the
      Site. Nothing herein creates an employer-employee, agency or other
      relationship between AirMason and such individual. AirMason has no
      responsibility for, control over or liability related to recruiting,
      hiring or any other decisions related to employment with respect to the
      Documents on or individuals using the Site. The Site acts as, among other
      things, a venue for Users to create and edit Documents and for others to
      view such Documents when the User grants permission. AirMason does not
      review, screen, edit or monitor the Documents posted on our Site or make
      any judgments about or selections of Documents or individuals. AirMason is
      not involved in the actual transaction between a potential counterparty
      and any Users through the Site and has no control over the Documents or
      the quality, truth, accuracy, reliability, completeness or timeliness of
      such materials. AirMason makes no representations or warranties about any
      material Users post on our Site. <Br />
      4. Special Notice about the AirMasons status and/or relationship with
      User: We are not licensed attorneys or a law firm, nor do we claim to be.
      By using this Site, you acknowledge that AirMason and its affiliates are
      not providing you with legal advice or acting as your attorney, and you
      assume full responsibility for any outcomes or costs associated with your
      use of this Site. AirMason does not claim that forms and Documents are
      complete and suitable for use in all situations and jurisdictions. You
      should seek your own legal counsel before relying on any AirMason Document
      for any contract, agreement or transaction which can reasonable be
      construed to be too complex for the types of Documents available on the
      Site. You hereby acknowledge that AirMason is not a party to any agreement
      that you enter into as a result of the use of any Document that you
      create, use or download from the Site. Use of the Site and any Document
      does not constitute an attorney-client relationship, joint venture or
      partnership between AirMason and any User or third party.
    </p>
    <h2>6. PAYMENT</h2>
    <p>
      1. While many of the services offered on the Site are provided free of
      charge, some of our Services require payment. In order to use our services
      that have fees associated with them, you must select a payment method and
      provide accurate billing and payment information when prompted. You agree
      to pay AirMason for all charges incurred under your account, including all
      applicable taxes, fees and surcharges. You authorize AirMason to charge
      your designated payment method for such charges. If AirMason, for any
      reason, does not receive payment from your designated payment method, you
      agree to pay all amounts due upon demand by AirMason or its operators.
      Every time you use the Site, you reaffirm that (i) AirMason (or its
      designated vendor acting as its billing agent) is authorized to charge
      your designated payment method; (ii) AirMason may submit charges incurred
      under your account for payment; and (iii) you will be responsible for such
      charges. <Br />
      2. Please note that AirMason uses a domestic or an international
      third-party payment service, the latter of which may result in
      international transactions fees billed to your credit card, (collectively,
      the "Payment Service"), to collect payments for all fees. At the time of
      purchase, you will provide AirMason your payment information and we will
      pass that along to the Payment Service. Please note that any international
      transaction fees that you may incur are charged by your bank and not by
      AirMason. All questions concerning such fees should be directed towards
      your bank. Any information you provide us in connection with payment that
      we provide to the Payment Service will be subject to the Payment Service's
      user agreement and privacy policy, not this Agreement or our Privacy
      Policy. You acknowledge and agree that AirMason is not, and will not be,
      responsible or liable for the Payment Service's services, its site or any
      acts or omissions of the Payment Service. Price and availability of any
      product or service offered through the Site are subject to change without
      notice. Refunds and exchanges will be subject to AirMason's refund and
      exchange policies then in effect. You agree to pay all charges that may be
      incurred by you or on your behalf through the Site, at the price(s) in
      effect when such charges are incurred, including without limitation, any
      taxes that may be applicable.
    </p>
    <h2>7. REFUND AND EXCHANGE POLICY</h2>
    <p>
      We want you to be 100% satisfied with our products and services. If you
      are less than satisfied or feel there has been an error in billing, please
      contact us immediately so that we can help you resolve the issue. If you
      are still not satisfied you may request a refund for up to{" "}
      {constant.trialDays} days after signing up for any service offered on the
      Site, inclusive of any free trial period. After {constant.trialDays} days
      you may cancel any subscription or access to the Site, but you will not be
      entitled to a refund.
    </p>
    <h2>8. THIRD PARTIES</h2>
    <p>
      1. Various third parties offer or provide materials through the pages on
      our Site. You also may obtain access to other sites on the Internet
      through the pages on our Site. By providing this service, AirMason is not
      undertaking any responsibility or liability for information or
      publications of third parties on the Internet even if you access them
      through these pages. Your correspondence or business dealings with, or
      participation in promotions of, advertisers found on or through the Site,
      including payment and delivery of related goods or services and any other
      terms, conditions, warranties or representations associated with such
      dealings, are solely between you and such third party. You agree that
      AirMason shall not be responsible or liable for any loss or damage of any
      sort incurred as the result of any such dealings or as the result of the
      presence of such third parties' links and/or ads on the Site and AirMason
      expressly disclaims any responsibility or liability for any material
      communicated by third parties through these pages or for any claims,
      damages or losses resulting from the use thereof. <Br />
      2. We and/or third-parties may make available through the Site services
      (for example, message boards, chat functionality and comment features on
      blogs, among other things) to which you are able to post information and
      materials (each, a "Forum"). PLEASE BE AWARE THAT SUCH FORUMS MAY BE
      PROVIDED BY A THIRD PARTY, AND MAY BE SUBJECT TO THE TERMS AND CONDITIONS
      OF SUCH THIRD PARTY AS WELL AS THIS AGREEMENT.
    </p>

    <h2>9. REPRESENTATIONS AND WARRANTIES</h2>
    <p>
      1. No Representations or Warranties by AirMason. AirMason makes no
      representations or warranties with respect to these pages, their Content
      and Materials (including, without limitation, material communicated by
      third parties) or communications from Customer Service representatives,
      all of which are provided on an "as is" and "as available" basis. AirMason
      disclaims all warranties, express or implied, including without limitation
      the implied warranties of merchantability and fitness for a particular
      purpose. AirMason makes no representations or warranties that the services
      provided by these pages or any customer service representatives will be
      uninterrupted or error-free, that defects will be corrected or that the
      pages or the server that makes them available are free from viruses, worms
      or other harmful components. If your use of the Site results directly or
      indirectly in the need for servicing or replacing equipment or data,
      AirMason is not responsible for those costs. AirMason also makes no
      representations or warranties as to whether the information accessible via
      these pages or customer service representatives, including information
      posted by Users or third parties, is accurate, complete, current, reliable
      or truthful. AirMason assumes no responsibility for the timeliness,
      deletion, mis-delivery or failure to store any user communications or
      personalization settings. <Br />
      2. Nothing on the Site shall be considered an endorsement, representation
      or warranty with respect to any User or third party, whether in regards to
      its web site, products, services, experience, recruiting, hiring or
      otherwise. No advice or information obtained by any User from AirMason or
      its personnel shall create any warranty not expressly provided for in this
      Agreement. You agree that any reliance on materials/information posted by
      Users or on any other form of communication with Users will be at your own
      risk. AirMason acts as, among other things, a portal for the online
      distribution and publication of User submitted information and has no
      obligation to screen communications or information in advance and is not
      responsible for screening or monitoring User posted material or
      information, although it reserves the right to do so.
      <Br /> 3. No Guaranty of Results. AirMason makes no representations or
      guarantees regarding the effectiveness or timeliness of the Site in
      meeting objectives of Users. AirMason does not guarantee that Documents
      created, checked or reviewed using the Site, Documents written by AirMason
      or materials posted by Users will result in an agreement, contract or
      successful application, for whatever reason made, made by any User or
      third party.
      <Br /> 4. User Representations and Warranties. You hereby represent and
      warrant that you (i) will not use the Content and Materials for commercial
      purposes and will not aggregate, redistribute or otherwise transmit the
      Content and Materials to any other individual or entity; and (ii) will not
      use (or plan, encourage or help others to use) the Site for any purpose or
      in any manner that is prohibited by these Terms of Use or by applicable
      law.
    </p>
    <h2> 10. LIABILITY DISCLAIMER </h2>
    <p>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT AIRMASON AND ITS PARENT,
      SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
      LICENSORS OR ANYONE ELSE WHO HAS BEEN INVOLVED IN THE CREATION,
      PRODUCTION, OR DELIVERY OF THESE PAGES, SERVICES, AND CONTENT AND
      MATERIALS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL,
      SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED
      TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA (INCLUDING, WITHOUT
      LIMITATION, ANY DOCUMENTS WRITTEN OR CREATED BY AIRMASON, AND ANY OTHER
      USER INFORMATION PROVIDED IN CONNECTION THEREWITH) OR OTHER INTANGIBLE
      LOSSES (EVEN IF AIRMASON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES), RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SITE;
      (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING
      FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
      MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SITE;
      (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
      (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE; OR (V) ANY
      OTHER MATTER RELATING TO THE SITE. <Br />
      AIRMASON RESERVES THE RIGHT AT ANY TIME TO MODIFY OR DISCONTINUE,
      TEMPORARILY OR PERMANENTLY, THE SITE (OR ANY PART THEREOF) WITH OR WITHOUT
      NOTICE. YOU AGREE THAT AIRMASON SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD
      PARTY FOR ANY SUCH MODIFICATION, SUSPENSION OR DISCONTINUANCE OF THE SITE.
      THE LIMITATIONS SET FORTH IN THE PRECEDING PARAGRAPH MAY NOT APPLY TO YOU
      BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
      LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. IF ANY
      LIMITATIONS ARE HELD INAPPLICABLE OR UNENFORCEABLE FOR ANY REASON, THEN
      AIRMASON'S MAXIMUM LIABILITY TO YOU FOR ANY NON-DIRECT TYPE OF DAMAGES
      SHALL BE LIMITED TO U.S. $50.00 IN THE AGGREGATE. IN NO EVENT SHALL
      AIRMASON (ITS PARENT, SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
      AGENTS, PARTNERS AND LICENSORS OR ANYONE ELSE WHO HAS BEEN INVOLVED IN THE
      CREATION, PRODUCTION, OR DELIVERY OF THESE PAGES), BE LIABLE FOR ANY
      DIRECT DAMAGES IN EXCESS IN THE AGGREGATE OF US $50.00 OR FIVE TIMES THE
      AMOUNT OF YOUR MOST RECENT MONTHLY PAYMENT (IN THE EVENT YOU ARE A PAYING
      SUBSCRIBER), WHICHEVER IS LESS. IF ANY PART OF THE EXCLUSIONS OF DAMAGES
      OR LIMITATIONS OF LIABILITY SET FORTH HEREIN IS UNENFORCEABLE UNDER
      APPLICABLE LAW, AIRMASON'S AGGREGATE LIABILITY RELATED THERETO WILL BE
      LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW, EVEN IF ANY REMEDY FAILS
      ITS ESSENTIAL PURPOSE.
      <Br /> BECAUSE USER AUTHENTICATION OVER THE INTERNET IS DIFFICULT,
      AIRMASON CANNOT AND DOES NOT CONFIRM EACH USER'S IDENTITY. WE ARE NOT
      INVOLVED IN USER-TO-USER DEALINGS NOR DO WE CONTROL THE BEHAVIOR OF
      PARTICIPANTS ON ANY SITE. THEREFORE, IN THE EVENT THAT YOU HAVE A DISPUTE
      WITH ONE OR MORE USERS, YOU RELEASE AIRMASON (AND OUR AGENTS AND
      EMPLOYEES) FROM CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL AND
      DIRECT AND INDIRECT) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
      SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN
      ANY WAY CONNECTED WITH SUCH DISPUTES. IF YOU ARE A CALIFORNIA RESIDENT,
      YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: "A GENERAL
      RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
      SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
      WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER
      SETTLEMENT WITH THE DEBTOR."
    </p>
    <h2> 11. PROPRIETARY RIGHTS</h2>
    <p>
      1. The Site and all right, title and interest in and to the Site is the
      sole property of AirMason and/or its Affiliates (including parent company)
      or its licensors, and is protected by U.S. copyright and international
      treaties. AirMason reserves for itself and its licensors all other right,
      title and interest. "AirMason " and the AirMason design logo are
      registered service or trademarks and certain other names or logos are
      service marks or trademarks of AirMason. All related products and service
      names, design marks and slogans are also the service marks or trademarks
      of AirMason. In addition, the "look" and "feel" of the Site (including
      color combinations, button shapes, layout, design and all other graphical
      elements) are protected by AirMason's trademarks, service marks and
      copyrights. All other product and service marks contained on the Site are
      the trademarks of their respective owners. You agree that the Site and any
      software used in connection with the Site contain proprietary and
      confidential information that is protected by applicable intellectual
      property and other laws. You further agree that content, including any
      tests and/or reports, contained in advertisements or information presented
      to you through the Site or by advertisers is protected by copyrights,
      trademarks, service marks, patents or other proprietary rights and laws.
      Except as expressly permitted by applicable law or authorized by AirMason
      or its advertisers (as applicable), you agree not to modify, rent, lease,
      loan, sell, distribute or create derivative works based on the Service or
      the Software, in whole or in part. AirMason also reserves the right to use
      its work, including, without limitations, any Documents written by
      AirMason, in instructional or promotional material subject to the Privacy
      Policy.
      <Br /> 2. For the avoidance of doubt, each User retains ownership of any
      materials you, the User, submits through the Site (each, a "Submission").
      However, in order to make your Submissions available on the Site and to
      best provide you with services tailored to such Submissions, AirMason
      needs certain rights to your Submissions. As such, by submitting materials
      to our Site, including Documents, you grant to AirMason and its designees
      a worldwide, non-exclusive, transferable, royalty-free, fully-paid up,
      perpetual, irrevocable right and license, without compensation or further
      notice to you: (a) to use, reproduce, distribute, adapt (including without
      limitation edit, modify, translate, and reformat), create derivative works
      of, publicly display and publicly perform such Submission, and the
      contents of such Submission, in any media now known or hereafter
      developed, for AirMason's business, marketing and promotional purposes and
      (b) to sublicense the foregoing rights, through multiple tiers, to the
      maximum extent permitted by applicable law; provided however, that in each
      case, with respect to the Document materials you submit, such rights shall
      be subject to any restrictions or limitations established by you in
      connection with your creation or maintenance of such Document (for
      example, if you limit availability of your Document, AirMason will use
      commercially reasonable efforts to ensure that the availability of your
      Document complies with the settings you selected). By way of further
      explanation, AirMason would not be able to perform many of the services we
      offer without your granting AirMason these rights. For example, without
      the right to "adapt," we would be unable to make the necessary changes to
      the Submissions so that the Submissions meet our technical criteria;
      without the right to reproduce, we would be unable to make copies of
      Submissions on our servers to post the Submissions. As noted, this license
      is non-exclusive (so you can license your Submissions to others),
      worldwide (as the Internet is global in its reach), fully-paid up and
      royalty-free (so that we do not have to pay you for posting your
      Submissions) and sublicenseable through multiple tiers (so that we can use
      our subcontractors to provide Services).
      <Br /> 3. For each Submission, you represent and warrant that you have all
      rights necessary for you to grant the licenses granted in this section and
      that such Submission and your provision thereof to and through the Site
      comply with all applicable laws, rules and regulations. You acknowledge
      that you are solely responsible for verifying any and all information
      contained in any Submission, including, without limitation, confirming
      your own data, terms or language, and that AirMason is not responsible for
      correcting any information provided to any third party. You further
      irrevocably waive any "moral rights" or other rights with respect to
      attribution of authorship or integrity of materials regarding each
      Submission that you may have under any applicable law under any legal
      theory. 4. Claims of Copyright Infringement. If you have a good faith
      belief that materials available on the Site infringe your copyright, send
      AirMason a notice requesting that we remove the material or block access
      to it. If you believe in good faith that someone has wrongly filed a
      notice of copyright infringement against you, send AirMason a
      counter-notice. Notices and counter-notices must meet the then-current
      statutory requirements imposed by federal law (see
      http://www.copyright.gov/ for details). See below "Information or
      Complaints" for where to send notices or counter-notices.
    </p>
    <h2> 12. FITNESS FOR USE</h2>
    <p>
      AirMason makes no representation that materials on these pages are
      appropriate or available for use in any particular location. Those who
      choose to access these pages do so on their own initiative and are
      responsible for compliance with local laws.
    </p>
    <h2> 13. BINDING ARBITRATION</h2>
    <p>
      THIS SECTION PROVIDES FOR RESOLUTION OF DISPUTES THROUGH FINAL AND BINDING
      ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE
      OR JURY. As precursor to arbitrating any dispute, you must first contact
      AirMason by email at support@AirMason.com, so that we can work to resolve
      the dispute. Similarly, we must first contact you at the most recent email
      address that we have on file for you before Bringing a dispute to
      arbitration. In the event that we cannot resolve a dispute within sixty
      (60) days of notification by either party or if AirMason is unable to
      reach you, you do not respond to our efforts to contact you or you fail to
      engage in good-faith settlement discussions with AirMason, then the
      following procedures shall apply. All disputes arising under or relating
      to this Agreement shall be settled by arbitration administered by the
      American Arbitration Association under its Commercial Arbitration Rules
      and Supplementary Procedures for Consumer-Related Disputes ("Supplementary
      Procedures"), as amended by the terms of this Agreement. The Supplementary
      Procedures are available online at www.adr.org. To the extent that the
      arbitrator deems reasonable, the arbitrator shall conduct hearings, if
      any, by teleconference or videoconference, rather than by personal
      appearances. Any in-person appearances requested by the arbitrator shall
      be held in San Francisco, CA. The arbitrator's decision shall be based
      upon the substantive laws of the State of California without regard to its
      principles of conflicts of law. Arbitration proceedings shall be conducted
      in English and shall be conducted in a manner that preserves
      confidentiality. The arbitrator's decision shall follow the plain meaning
      of the relevant documents and shall be final and binding. The award
      rendered by the arbitrator(s) may be confirmed and enforced in any court
      having jurisdiction thereof. Notwithstanding any of the foregoing,
      AirMason may seek injunctive relief in U.S. state or federal courts for
      protection of its intellectual property rights (including the rights of
      its licensors), and you agree to exclusive jurisdiction by the federal and
      state courts located in the State of California and waive any
      jurisdictional, venue or inconvenient forum objections to such courts.
    </p>
    <h2> 14. GENERAL TERMS</h2>
    <p>
      1. Entire Agreement. These Terms of Use constitute the entire agreement
      between you and AirMason and govern your use of the Site, superseding any
      prior agreements between you and AirMason with respect to the Site. You
      also may be subject to additional terms and conditions, e.g., our Privacy
      Policy, as set forth on our Site or when you use or purchase certain other
      AirMason services, affiliate services or third-party content or
      third-party services. <Br />
      2. User Remedies. You acknowledge that, except as expressly provided
      elsewhere in this Agreement, your only right with respect to any
      dissatisfaction with any modification or discontinuation of service made
      by AirMason or any policies or practices in providing this Site or our
      products, including without limitation any change in content or any change
      in the amount or type of fees or charges associated with our products, is
      to cancel your subscription or user account, as applicable. In no event
      shall you seek or be entitled to rescission, injunctive or other equitable
      relief, or to enjoin or restrain the operation of the AirMason 's
      services, exploitation of any advertising or other materials issued in
      connection therewith, or exploitation of AirMason 's services or any
      content or other material used or displayed through AirMason's Sites.
      <Br /> 3. Choice of Law and Forum. The Terms of Use and the relationship
      between you and AirMason shall be governed by the laws of the State of
      California without regard to its conflict of law provisions. You and
      AirMason agree to submit to the personal and exclusive jurisdiction of the
      courts located within the county of San Francisco, California.
      <Br /> 4. Indemnity. You agree to defend, indemnify, and hold harmless
      AirMason (and its officers, directors, employees and agents) from and
      against any third party claims, actions or demands (including, without
      limitation, costs, damages and reasonable legal and accounting fees)
      alleging or resulting from or in connection with your use of the Site,
      Documents posted by you on the Site or distributed by you through the Site
      or your Breach of this Agreement. AirMason shall use reasonable efforts to
      provide you prompt notice of any such claim, suit, or proceeding and may
      assist you, at your expense, in defending any such claim, suit or
      proceeding.
      <Br /> 5. Waiver and Severability of Terms. The failure of AirMason to
      exercise or enforce any right or provision of the Terms of Use shall not
      constitute a waiver of such right or provision. If any provision of the
      Terms of Use is found by a court of competent jurisdiction to be invalid,
      the parties nevertheless agree that the court should endeavor to give
      effect to the parties' intentions as reflected in the provision, and the
      other provisions of the Terms of Use remain in full force and effect.
      <Br /> 6. Statute of Limitations. You agree that regardless of any statute
      or law to the contrary, any claim or cause of action arising out of or
      related to use of the Site or the Terms of Use must be filed within one
      (1) year after such claim or cause of action arose or be forever barred.
      <Br />
      7.Amendment. AirMason reserves the right to update or amend these Terms of
      Use at any time without notice by posting Updated Terms of Use to this web
      page, so you should visit this page often.
      <Br /> 8. Term and Termination. This Agreement is effective until
      terminated. AirMason, at its sole discretion, may terminate your access to
      or use of the Site, at any time and for any reason. Such termination,
      including deactivation or deletion of your password and user name, and all
      related information and files associated with it may happen without prior
      notice. AirMason shall not be liable to you or any third party for any
      termination of your access to the Site or to any such information or files
      and shall not be required to make such information or files available to
      you after any termination. Sections 6-14 shall survive such termination.
      <Br />
      9. Filtering. Please note that parental control protections (such as
      computer hardware, software or filtering services) are commercially
      available that may help you to limit access to material that is harmful to
      minors. Information on these types of parental controls is available at
      GetNetWise (http://kids.getnetwise.org/) and OnGuard Online
      (http://onguardonline.gov/). AirMason does not endorse any of the products
      or services listed at these sites. <Br />
      10. Notice. For purposes of service messages and notices about the
      services to you, notice shall consist of an email from AirMason to the
      email address associated with your account, regardless of any other
      information we may have. AirMason also may, at its option, communicate
      with you through your account or through other means including email,
      mobile number, telephone, or delivery services including the US Postal
      Service about your account or services associated with AirMason. AirMason
      shall have no liability associated with or arising from your failure to
      maintain accurate contact or other information, including, but not limited
      to, liability your failure to receive important information about our
      services. <Br />
      11. Customer Service. As part of the services AirMason offers, we may make
      live customer service agents available to our Users to discuss
      transactional or other issues telephonically or through electronic or
      written communications. Customer Service Representatives are not permitted
      and do not have authority to bind the company or to contravene these Terms
      of Use. In addition, AirMason expressly disclaims any liability for
      information provided by its customer service representatives to the extent
      the information is beyond or inconsistent with information set forth
      herein. <Br />
      12. Section Titles. The section titles in the Terms of Use are for
      convenience only and have no legal or contractual effect. <Br />
      13. Privacy Information. AirMason recognizes the importance of protecting
      the information that we collect from customers during the process of using
      the AirMason site. For further information, please review our complete
      Privacy Policy which is fully incorporated by reference into these Terms
      of Use. If you have questions about any of the provisions described above,
      please contact us at support@AirMason.com.
    </p>
  </div>
)

const Terms = ({}) => {
  return (
    <React.Fragment>
      <Header />
      <WidthWrapper>
        <TermsDoc />
      </WidthWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default Terms
